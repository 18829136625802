import { h } from "preact"
import { useRef, useEffect, useMemo } from "preact/hooks"
import { useDispatch, useSelector } from "react-redux"
import StepsAnswersBox from "./StepsBox"
import {
  DescriptionListItems,
  LeadWorkflowSteps,
  RootStates,
  FormData,
  LeadWorkflowValues
} from "@types"
import {
  calcDownPayment,
  calcDownPercentage,
  numberToUSD,
  parseAddress,
  splitAndCapitalize
} from "@helpers"
import { getPricingBeforeLead, updateFormValues } from "@actions"

const FIELDS = [
  "loan_purpose",
  "list_price",
  "down_payment",
  "loan_balance",
  "cash_out",
  "residency_type",
  "location",
  "credit_score",
  "property_type",
  "household_income"
]

interface Props {
  className?: string
}

const StepsAnswers = ({ className = "" }: Props) => {
  const dispatch = useDispatch()
  const initialFormFieldsRef = useRef<Partial<FormData> | null>(null)

  // Get the raw data from Redux
  const { steps: formSteps = [], values } = useSelector(
    ({ wizard }: RootStates) => {
      // Cast wizard to the correct type to access its properties
      const wizardState = wizard as
        | { steps?: any[]; values?: LeadWorkflowValues }
        | undefined
      return {
        steps: wizardState?.steps ?? [],
        values: wizardState?.values ?? ({} as LeadWorkflowValues)
      }
    }
  )

  // Process the data with useMemo to avoid recalculation on every render
  const { items, formFields } = useMemo(() => {
    const formdata = values?.steps ?? {}
    const postal_code = values?.locations?.location?.postal_code ?? ""

    const formFields: Partial<
      FormData & { down_percentage?: number; loan_balance?: number }
    > = {}

    const items: DescriptionListItems = formSteps
      .filter(step => {
        // Check if the step has a condition
        if (step.condition) {
          // Check if the condition is met
          // For example, if condition is {loan_purpose: "purchase"},
          // the value in formData for loan_purpose must be "purchase"
          const conditionKey = Object.keys(step.condition)[0]
          const conditionValue =
            step.condition[conditionKey as keyof typeof step.condition]
          const formValue = formdata[conditionKey as keyof LeadWorkflowSteps]

          const include = formValue === conditionValue

          return include
        } else if (!FIELDS.includes(step.id)) {
          return false
        }

        // If no condition, include the step
        return true
      })
      .map(step => {
        const stepData = formdata?.[step.id as keyof LeadWorkflowSteps]

        const term =
          step.id === "location" ? "Zip code" : splitAndCapitalize(step.id)

        // Special case: if step.id is "location", use postal_code as description
        let description =
          step.id === "location" ? postal_code : (stepData ?? "")

        formFields[step.id as keyof FormData] = description as any

        if (step.id === "down_payment") {
          let down_percentage = calcDownPercentage({
            homePrice: formFields.list_price ?? 0,
            down_payment: description as number
          })

          formFields.down_percentage = down_percentage
        }

        // Apply splitAndCapitalize to string descriptions
        if (typeof description === "string") {
          description = splitAndCapitalize(description)
        }

        if (typeof description === "number") {
          if (step.id === "credit_score") {
            description = description + ""
          } else {
            description = numberToUSD(description, true)
          }
        }

        return {
          term,
          description
        }
      })

    return {
      items,
      formFields
    }
  }, [formSteps, values])

  useEffect(() => {
    if (formFields && !initialFormFieldsRef.current) {
      initialFormFieldsRef.current = { ...formFields }
    }
  }, [formFields])

  const onChange = (value: any, key: string) => {
    const step = formSteps.find(step => step.id === key)

    let isMultipleFields = false

    let down_payment = 0
    let down_percentage = 0
    switch (key) {
      case "list_price":
        isMultipleFields = true
        down_payment = calcDownPayment({
          homePrice: value,
          downPercentage: formFields.down_percentage ?? 0
        })

        value = { [key]: value, down_payment }

        break
      case "down_payment":
        isMultipleFields = true
        down_percentage = calcDownPercentage({
          homePrice: formFields.list_price ?? 0,
          down_payment: value
        })

        value = { [key]: value, down_percentage }

        break
      case "down_percentage":
        isMultipleFields = true
        down_payment = calcDownPayment({
          homePrice: formFields.list_price ?? 0,
          downPercentage: value
        })

        value = {
          [key]: value,
          down_payment
        }

        break
      case "location":
        const addressObj =
          value.address_components as google.maps.GeocoderAddressComponent[]

        const postal_code = addressObj?.find(
          item => item.types[0] === "postal_code"
        )?.long_name

        value = {
          value: value.formatted_address,
          ...parseAddress(addressObj),
          postal_code
        }
        break
      case "loan_purpose":
        isMultipleFields = true

        if (value === "purchase") {
          down_payment =
            (formFields?.list_price ?? 0) - (formFields?.loan_balance ?? 0)
          down_percentage = calcDownPercentage({
            homePrice: formFields.list_price ?? 0,
            down_payment
          })

          value = {
            [key]: value,
            down_payment,
            down_percentage
          }
        } else {
          const loan_balance =
            (formFields?.list_price ?? 0) - (formFields?.down_payment ?? 0)

          value = {
            [key]: value,
            loan_balance
          }
        }

        break
    }

    dispatch(
      updateFormValues({
        value,
        key: isMultipleFields ? "multipleFields" : key,
        type: step?.type ?? "text"
      })
    )
  }

  const handleSave = () => {
    initialFormFieldsRef.current = { ...formFields }
    dispatch(getPricingBeforeLead())
  }

  const handleCancel = () => {
    if (initialFormFieldsRef.current) {
      dispatch(
        updateFormValues({
          value: initialFormFieldsRef.current,
          key: "multipleFields"
        })
      )
    }
  }

  return (
    <StepsAnswersBox
      className={className}
      items={items}
      formFields={formFields}
      onChange={onChange}
      onSave={handleSave}
      onCancel={handleCancel}
    />
  )
}

export default StepsAnswers
